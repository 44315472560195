<template>
    <div class="AllocateEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="editForm"
            size="small"
            label-width="80px"
        >
            <el-card style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="类型">
                            <el-select placeholder="请选择" v-model="form.allocateType" disabled>
                                <el-option label="内部调拨" :value="1" />
                                <el-option label="自建调拨" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调入机构">
                            <el-input :value="formExtend.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调出机构">
                            <el-input :value="formExtend.outDeptName" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="formExtend.creator" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input readonly v-model="formExtend.createTime" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="showCustomer">
                    <el-col :span="8">
                        <el-form-item label="客户" prop="customer">
                            <el-input v-model="form.customer" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户电话" prop="customerMobile">
                            <el-input v-model="form.customerMobile" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户地址" prop="customerAddress">
                            <el-input v-model="form.customerAddress" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <ef-remark v-model="form.remark" />
                </el-row>
            </el-card>
            <el-card style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton"
                    >查询并添加</el-button
                >
                <el-button type="primary" @click="showPickUpGoods" size="small" :disabled="disableShowPickUpGoodsDialog"
                    >选择商品基本信息</el-button
                >
                <el-button
                    type="primary"
                    :loading="saveLoading"
                    @click="handleSave"
                    size="small"
                    :disabled="disableSaveButton"
                    >保存</el-button
                >
            </el-card>
            <el-card style="margin-top: 8px">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    v-loading="tableLoading"
                    :highlight-current-row="true"
                    max-height="520"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品编码" width="200" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.goodsCode'"
                                :rules="rules.goodsCode"
                            >
                                {{ scope.row.goodsCode }}
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="150">
                        <template slot-scope="scope">
                            {{
                                skuByGoodsCode(scope.row.goodsCode)
                                    .bars.map((d) => d)
                                    .join(' / ')
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | goodsType }}
                        </template>
                    </el-table-column>
                    <el-table-column label="助记码" width="100">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).fastBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="自编码" width="100">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).customBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="调拨价" width="170" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input
                                    v-model="scope.row.showPrices"
                                    :precision="2"
                                    @change="computeThenChangeMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="调拨数量" width="170">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.counts"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                    @change="computeThenChangeMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="调拨金额" width="150" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <span>{{ scope.row.showMoney }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pickUpGoods ref="pickUpGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
    </div>
</template>
<script>
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import EfRemark from 'components/EfRemark';
import { get, patchByFormData } from 'request/http';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import MoneyUtils from 'js/MoneyUtils';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import EfPriceInput from 'components/EfPriceInput';
import {
    goodsCodeValidateRule,
    mobileValidateRule,
    pricesValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import { countGreaterThanZeroValidateRule, countValidateRule } from 'js/validate/ValidateStockBizRule';

export default {
    name: 'AllocateEdit',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    components: { PickUpGoods, EfRemark, EfStockBizCountInput, EfPriceInput },
    data() {
        return {
            search: '',
            formExtend: {
                creator: '',
                createTime: '',
                deptName: '',
                outDeptName: '',
            },
            form: {
                deptCode: '',
                outDeptCode: '',
                remark: '',
                allocateType: null,
                customer: '',
                customerMobile: '',
                customerAddress: '',
                detailParams: [],
                delCodes: [],
            },
            goodsDetailMap: new Map(),
            detailParamPropertyArrForShow: [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
                new PropertyConverter(null, 'showMoney', (showMoney, detailParam) => {
                    return Util.isEmpty(showMoney) ? this.computeMoney(detailParam) : showMoney;
                }),
            ],
            detailParamPropertyArrForAdd: [
                'goodsCode',
                new PropertyConverter(null, 'codes'),
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(null, 'showMoney'),
                new PropertyConverter('wholeSalePrice', 'showPrices', (wholeSalePrice) => {
                    //将所选商品的批发价作为默认价格
                    return MoneyUtils.moneyToYuan(wholeSalePrice);
                }),
            ],
            detailParamPropertyArrForSubmit: [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter('showPrices', 'prices', (price) => {
                    return MoneyUtils.moneyToDb(price);
                }),
            ],
            rules: {
                customer: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,10}$/,
                    message: '至少两个中文汉字或字母',
                    trigger: ['blur'],
                },
                customerMobile: mobileValidateRule(false),
                customerAddress: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa50-9]{2,30}$/,
                    message: '请正确输入客户地址',
                    trigger: ['blur'],
                },
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                showPrices: pricesValidateRule(),
                counts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                    countGreaterThanZeroValidateRule(),
                ],
            },
            tableLoading: true,
            saveLoading: false,
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await get(`/stock/allocate/info_c/${this.code}`);
            Util.copyProperties(rst, this.form);
            Util.copyProperties(rst, this.formExtend);
            this.formExtend.outDeptName = rst.repoName;
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(rst, this.detailParamPropertyArrForShow);
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
            this.tableLoading = false;
        })();
    },
    computed: {
        showCustomer() {
            return !Util.isEmpty(this.form.outDeptCode);
        },
        disableQueryAndAddButton() {
            return (
                (Util.isEmpty(this.form.outDeptCode) && Util.isEmpty(this.form.deptCode)) || Util.isEmpty(this.search)
            );
        },
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.outDeptCode) && Util.isEmpty(this.form.deptCode);
        },
        disableSaveButton() {
            return (
                (Util.isEmpty(this.form.outDeptCode) && Util.isEmpty(this.form.deptCode)) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    methods: {
        pickUpGoodsQueryParam() {
            let deptCode;
            let deptName;
            if (this.form.allocateType === 1 || !Util.isEmpty(this.form.outDeptCode)) {
                deptCode = this.form.outDeptCode;
                deptName = this.formExtend.outDeptName;
            } else {
                deptCode = this.form.deptCode;
                deptName = this.formExtend.deptName;
            }
            return {
                deptCode: deptCode,
                deptName: deptName,
                goodsStatusIn: [0, 1],
                page: 1,
                limit: 100,
                showPricePropertyObj: {
                    factoryPrice: false,
                    wholeSalePrice: true,
                    retailPrice: false,
                    planCostPrice: false,
                    wmaCostPrice: false,
                },
            };
        },
        handleDelete(index) {
            const detailCode = this.form.detailParams.splice(index, 1)[0].codes;
            if (Util.isEmpty(detailCode)) {
                //不存在，说明是单据详情中新增的商品被删除，则不需要处理
                return;
            }
            this.form.delCodes.push(detailCode);
        },
        showPickUpGoods() {
            const pickUpGoodsQueryParam = this.pickUpGoodsQueryParam();
            this.$refs.pickUpGoods.show(pickUpGoodsQueryParam, pickUpGoodsQueryParam.deptName);
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
                this.$refs.pickUpGoods.dialogFormVisible = false;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({
                ...this.pickUpGoodsQueryParam(),
                search: this.form.search,
            });
            this.setSelectedGoods(rst.data);
        },
        handleSave() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                this.saveLoading = true;
                //将商品详情列表转换成多字段数组形式
                const updateParam = {
                    ...this.form,
                    ...StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                        this.form.detailParams,
                        this.detailParamPropertyArrForSubmit
                    ),
                };
                //删除非提交必要字段数据
                delete updateParam.detailParams;
                await patchByFormData(`/stock/allocate/patchExtend/${this.code}`, updateParam).finally(() => {
                    this.saveLoading = false;
                });
                this.$message.success('保存成功');
                this.goBackAndReload();
            });
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        computeMoney(row) {
            //计算金额
            return MoneyUtils.moneyToFour(
                MoneyUtils.moneyToDb(
                    this.decimal(row.counts || 0)
                        .mul(row.showPrices || 0)
                        .toNumber()
                )
            );
        },
        computeThenChangeMoney(row) {
            row.showMoney = this.computeMoney(row);
        },
    },
};
</script>
